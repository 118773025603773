<template>
  <v-row>
    <template v-if="!isPassoAltro && !pezzo['data_completamento']">
      <v-col cols="12" sm="8" v-if="passo['requireImage']">
        <v-btn
          v-if="imageUploaded"
          elevation="0"
          :ripple="false"
          @click="$emit('inviaImmagine')"
          height="43"
          class="
            font-weight-600
            text-capitalize
            btn-primary
            py-3
            px-6
            rounded-sm
          "
          block
          color="success"
          >
            <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
            <span v-else>Salva Immagine</span>
        </v-btn>
      </v-col>
      <v-col cols="6" sm="4" v-if="!passo['requireImage']">
        <v-btn
          elevation="0"
          :ripple="false"
          @click="$emit('updateCheckPasso', true)"
          height="43"
          class="
            font-weight-600
            text-capitalize
            btn-primary
            py-3
            px-6
            rounded-sm
          "
          block
          color="success"
          >Conforme</v-btn
        ></v-col
      >
      <v-col cols="6" sm="4" v-if="!passo['requireImage']">
        <v-btn
          elevation="0"
          :ripple="false"
          @click="$emit('updateCheckPasso', false)"
          height="43"
          block
          class="
            font-weight-600
            text-capitalize
            btn-primary
            py-3
            px-2
            rounded-sm
          "
          color="error"
          >Non Conforme</v-btn
        ></v-col
      >
      <v-col cols="12" sm="4">
        <v-btn
          elevation="0"
          :ripple="false"
          @click="$emit('nextPasso')"
          height="43"
          block
          class="
            font-weight-600
            text-capitalize
            btn-primary
            py-3
            px-6
            rounded-sm
          "
          color="grey"
          >Salta</v-btn
        ></v-col
      >
    </template>
    <v-col cols="6" offset="3" sm="6" offset-sm="3" v-if="isPassoAltro">
      <v-btn
        elevation="0"
        :ripple="false"
        @click="$emit('salvaAltro')"
        height="43"
        class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm"
        block
        color="success"
        >Salva</v-btn
      ></v-col
    >
  </v-row>
</template>

<script>
export default {
  name: "BottoniPezzo",
  props: ["pezzo", "passo", "isPassoAltro", "imageUploaded", "isLoading"],
};
</script>
